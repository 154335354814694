import { format, subMonths } from "date-fns";
import React from "react"
import { useEffect } from "react"
import { useQuery } from "react-query";
import { formatMoney, toStartCase } from "utils/functions"
import makeData from "utils/makeData"
import ButtonWithTips from "components/ButtonWithTips";
import useAdminActions from "hook/useAdminActions";


const useUserReport = () => {
  const { getAllDonors } = useAdminActions()
  const [page, setPage] = React.useState(1);
  const [pagesize, setPageSize] = React.useState(100);
  const [searchLevel, setSearchLevel] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [selectedState2, setSelectedState2] = React.useState('')
    const [selectedCity2, setSelectedCity2] = React.useState('')
    const [sFrom, setFrom] =React.useState('')
  const [sTo, setTo] =React.useState('')
  const { data, isLoading } = useQuery(['users', page,search,searchLevel, selectedState2,selectedCity2,sFrom,sTo], () => getAllDonors(page,search,searchLevel, selectedState2,selectedCity2,sFrom,sTo), {
    select: data => data.data.users.data
  })
  const [, setData] = React.useState(React.useMemo(() => makeData('aausers', 100), []))
  const [currentData, setCurrentData] = React.useState(null)
  const [current, setCurrent] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [bar_data, setBar_data] = React.useState([])
  const [line_data, setLine_data] = React.useState([])
  const [line2_data, setLine2_data] = React.useState([])
  const [pie_data, setPie_data] = React.useState([])
  const [skipPageReset, setSkipPageReset] = React.useState(false)

  const updateMyData = () => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row) => {
        if (row.id === currentData.id) {
          return {
            ...currentData
          }
        }
        return row
      })
    )

    setOpen(false)
  }

  const dummy_data = {
    labels: bar_data.length ? bar_data.map(item => format(new Date(item.date), 'MMM')) : [],
    datasets: [
      {
        label: "Users",
        backgroundColor: "rgba(2,117,216,1)",
        borderColor: "rgba(2,117,216,1)",
        data: bar_data.length ? bar_data.map(item => item.amount) : [],
      },
    ],
  };


  const dummy_line2_data = {
    labels: line2_data.length ? line2_data.map(item => format(new Date(item.date), 'MMM dd')) : [],
    datasets: [
      {
        fill: true,
        label: "Total Users",
        lineTension: 0.3,
        backgroundColor: "rgba(2,117,216,0.2)",
        borderColor: "rgba(2,117,216,1)",
        pointRadius: 5,
        pointBackgroundColor: "rgba(2,117,216,1)",
        pointBorderColor: "rgba(255,255,255,0.8)",
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(2,117,216,1)",
        pointHitRadius: 50,
        pointBorderWidth: 2,
        data: line2_data.length ? line2_data.map(item => item.amount) : [],
      },
    ],
  };

  const dummy_line_data = {
    labels: line_data.length ? line_data.map(item => format(new Date(item.date), 'MMM dd')) : [],
    datasets: [
      {
        fill: true,
        label: "Total Users",
        lineTension: 0.3,
        backgroundColor: "rgba(2,117,216,0.2)",
        borderColor: "rgba(2,117,216,1)",
        pointRadius: 5,
        pointBackgroundColor: "rgba(2,117,216,1)",
        pointBorderColor: "rgba(255,255,255,0.8)",
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(2,117,216,1)",
        pointHitRadius: 50,
        pointBorderWidth: 2,
        data: line_data.length ? line_data.map(item => item.amount) : [],
      },
    ],
  };

  const dummy_pie_data = {
    labels: pie_data.length ? pie_data.map(item => item.cycle.split('/')[0]) : [],
    datasets: [
      {
        label: 'Total of Users',
        data: pie_data.length ? pie_data.map(item => item.amount) : [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  useEffect(() => {
    const donationReportByMonth = async () => {
      const today = format(new Date(), 'yyyy/MM/dd')
      const date = today.split('/')[0]
      const records = []
      const allRecord = []
      data.forEach(item => {
        if (item?.created_at?.includes(date)) {
          records.push({ date: format(new Date(item.created_at), 'yyyy/MM/dd'), amount: 0 })
        }
        allRecord.push({ cycle: format(new Date(item.created_at), 'yyyy/MM/dd'), amount: 0 })
      })


      const occurrencesAmount = records.sort((p1, p2) => (p1.date > p2.date) ? 1 : (p1.date < p2.date) ? -1 : 0).reduce(function (allCycles, cycle) {
        if (allCycles.some(function (e) {
          return e.date.split('/').splice(0, 2).join('/') === cycle.date.split('/').splice(0, 2).join('/')
        })) {
          allCycles.filter(function (e) {
            return e.date.split('/').splice(0, 2).join('/') === cycle.date.split('/').splice(0, 2).join('/')
          })[0].amount += 1
        } else {
          allCycles.push({
            date: cycle.date,
            amount: +1
          })
        }
        return allCycles
      }, []);

      const occurrencesCycle = allRecord.sort((p1, p2) => (p1.cycle > p2.cycle) ? 1 : (p1.cycle < p2.cycle) ? -1 : 0).reduce(function (allCycles, cycle) {
        if (allCycles.some(function (e) {
          return e.cycle.split('/')[0] === cycle.cycle.split('/')[0]
        })) {
          allCycles.filter(function (e) {
            return e.cycle.split('/')[0] === cycle.cycle.split('/')[0]
          })[0].amount += 1
        } else {
          allCycles.push({
            cycle: cycle.cycle,
            amount: +1
          })
        }
        return allCycles
      }, []);
      setPie_data(occurrencesCycle)

      setBar_data(occurrencesAmount)
    }

    const donationsThisMonthFunc = () => {
      const today = format(new Date(), 'yyyy/MM/dd')
      const lastMonth = format(subMonths(new Date(), 1), 'yyyy/MM/dd').split('/').splice(0, 2).join('-')

      const date = today.split('/').splice(0, 2).join('-')
      const records = []
      const lastMonthRecords = []
      data.forEach(item => {
        if (item?.created_at?.includes(date)) {
          records.push({ date: format(new Date(item.created_at), 'yyyy/MM/dd'), count: 0 })
        }
        if (item?.created_at?.includes(lastMonth)) {
          lastMonthRecords.push({ date: format(new Date(item.created_at), 'yyyy/MM/dd'), count: 0 })
        }
      })
      const reduced = records.sort((p1, p2) =>
        (p1.date > p2.date) ? 1 : (p1.date < p2.date) ? -1 : 0).reduce(function (allDates, date) {
          if (allDates.some(function (e) {
            return e.date === date.date
          })) {
            allDates.filter(function (e) {
              return e.date === date.date
            })[0].amount += 1
          } else {
            allDates.push({
              date: date.date,
              amount: +1
            })
          }
          return allDates
        }, []);

      const reduced2 = lastMonthRecords.sort((p1, p2) =>
        (p1.date > p2.date) ? 1 : (p1.date < p2.date) ? -1 : 0).reduce(function (allDates, date) {
          if (allDates.some(function (e) {
            return e.date === date.date
          })) {
            allDates.filter(function (e) {
              return e.date === date.date
            })[0].amount += 1
          } else {
            allDates.push({
              date: date.date,
              amount: 1
            })
          }
          return allDates
        }, []);

      setLine_data(reduced)
      setLine2_data(reduced2)
    }

    if (data) {
      donationReportByMonth()
      donationsThisMonthFunc()
    }
  }, [data])

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        id: 'date',
        accessor: row => format(new Date(row?.created_at), 'MMM dd yyyy'),
        filter: 'dateFilter'
      },
      {
        Header: 'Name',
        id: 'fullname',
        accessor: row => toStartCase(`${row?.first_name} ${row?.last_name}`),
      },
      {
        Header: 'Phone Number',
        id: 'user_phone_number',
        accessor: row => `${row?.phone_mobile}${row?.phone_home ? ', ' + row?.phone_home : ''}`,
      },
      {
        Header: 'Email',
        id: 'email',
        accessor: row => toStartCase(row?.email) || '-',
      },
      {
        Header: 'Address',
        id: 'address',
        accessor: row => row?.address || ' -',
      },
      {
        Header: 'Level',
        id: 'level',
        accessor: row => toStartCase(row?.category.name) || ' -',
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex flex-wrap gap-2 w-100">
            <ButtonWithTips id={`view`} tips="View Report Details" btnProps={{
              className: "btn btn-secondary", onClick: () => {
                setCurrent('view')
                handleclick(row.row.original)
              }
            }}><i className="fas fa-eye" /></ButtonWithTips>
          </div>
        ),
      }
    ],
    []
  )
  return { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns, data, setData, pagesize,
    current, edit, setEdit, dummy_data, dummy_line_data, dummy_line2_data, dummy_pie_data, isLoading,setFrom,setTo,sFrom,sTo,
  setSearch, setSearchLevel,setSelectedCity2,setSelectedState2 ,searchLevel,selectedCity2,selectedState2,search}
}

export default useUserReport;
