import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { notify } from "components/Alerts";
import useGenericActions from "./useGenericAction";
import useAuth from "./useAuth";
import { useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authAtom } from "./state/auth";
import { userSelector } from "./state/user";
import { donation_groups } from 'utils/constants'
import ToolTips from "components/ToolTips";

const validationSchema = Yup.object({
  anonymous: Yup.boolean(),
  first_name: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s .!?,"-]+$/,
      'field accepts only string, numbers and hyphens'
    )
    .required('Field cannot be empty'),
  last_name: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s .!?,"-]+$/,
      'field accepts only string, numbers and hyphens'
    )
    .required('Field cannot be empty'),
  donation_cause: Yup.object()
    .required('Field cannot be empty'),
  email: Yup.string()
    .email('must be valid email')
    .required('Must not be empty'),
  phone_home: Yup.string()
    .length(13, 'Phone number must be 11 digits only')
    .matches(/^\d+$/, 'Phone number must be digit 0-9 only'),
  phone_mobile: Yup.string()
    .required('Field cannot be empty')
    .length(13, 'Phone number must be 11 digits only')
    .matches(/^\d+$/, 'Phone number must be digit 0-9 only'),
  dob_month: Yup.string().when("anonymous", {
    is: false,
    then: Yup
      .string()
      .required("Field cannot be empty")
  }),
  dob_day: Yup.number().when("anonymous", {
    is: false,
    then: Yup
      .number().when('dob_month', {
        is: (dob_month) => ['0', '2', '4', '6', '7', '9', '11'].includes(dob_month) && !['February'].includes(dob_month),
        then: Yup.number()
          .positive('Must be a positive number')
          .lessThan(32, 'Values must be 31 or less').required('Field is required'),
        otherwise: Yup.number().when('dob_month', {
          is: (dob_month) => ['1'].includes(dob_month),
          then: Yup.number()
            .positive('Must be a positive number')
            .lessThan(30, 'Values must be 29 or less').required('Field is required'),
          otherwise: Yup.number().positive('Must be a positive number')
            .lessThan(31, 'Values must be 30 or less').required('Field is required'),
        }),
      }),
    otherwise: Yup
      .number().when('dob_month', {
        is: (dob_month) => ['0', '2', '4', '6', '7', '9', '11'].includes(dob_month) && !['February'].includes(dob_month),
        then: Yup.number()
          .positive('Must be a positive number')
          .lessThan(32, 'Values must be 31 or less').required('Field is required'),
        otherwise: Yup.number().when('dob_month', {
          is: (dob_month) => ['1'].includes(dob_month),
          then: Yup.number()
            .positive('Must be a positive number')
            .lessThan(30, 'Values must be 29 or less').required('Field is required'),
          otherwise: Yup.number().positive('Must be a positive number')
            .lessThan(31, 'Values must be 30 or less'),
        })
      })
  }),
  gender: Yup.string().when("anonymous", {
    is: false,
    then: Yup
      .string()
      .required("Field cannot be empty")
  }),
  address: Yup.string().when("anonymous", {
    is: false,
    then: Yup
      .string().matches(
        /^[a-zA-Z0-9\s .!?,"-]+$/,
        'field accepts only string, commas, numbers and hyphens'
      )
      .required("Field cannot be empty")
  }),
  country_id: Yup.number().when("anonymous", {
    is: false,
    then: Yup
      .number()
      .required("Field cannot be empty")
  }),
  state_id: Yup.number().when("anonymous", {
    is: false,
    then: Yup
      .number()
      .required("Field cannot be empty")
  }),
  city_id: Yup.number().when("anonymous", {
    is: false,
    then: Yup
      .number()
      .required("Field cannot be empty")
  }),
  amount: Yup.string()
    .matches(
      /^[0-9\s,]+$/,
      'field accepts only string, commas, numbers and hyphens'
    )
    .required("Field cannot be empty"),
  password: Yup.string().when("anonymous", {
    is: false,
    then: Yup
      .string()
      .required("Field cannot be empty")
  }),
  password_confirmation: Yup.string().when("anonymous", {
    is: false,
    then: Yup
      .string().oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Passwords must match')
  })
  ,
})

const validationSchemaDonations = Yup.object({
  first_name: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s .!?,"-]+$/,
      'field accepts only string, numbers and hyphens'
    )
    .required('Field cannot be empty'),
  last_name: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s .!?,"-]+$/,
      'field accepts only string, numbers and hyphens'
    )
    .required('Field cannot be empty'),
  email: Yup.string()
    .email('must be valid email')
    .required('Must not be empty'),
  phone_home: Yup.string()
    .required('Field cannot be empty')
    .length(11, 'Phone number must be 11 digits only')
    .matches(/^\d+$/, 'Phone number must be digit 0-9 only'),
  // phone_mobile: Yup.string()
  //   .required('Field cannot be empty'),
    // .length(14, 'Phone number must be 11 digits only')
    // .matches(/^\d+$/, 'Phone number must be digit 0-9 only'),
  amount: Yup.string()
    .matches(
      /^[0-9\s,]+$/,
      'field accepts only commas and numbers'
    )
    .required('Must not be empty'),
})

const useDonation = () => {
  const setAuth = useSetRecoilState(authAtom)
  const userDetails = useRecoilValue(userSelector)
  const donation_types = [
    { id: 1, value: 'one-off', label: 'One Off' },
    { id: 2, value: 'recurrent', label: 'Recurrent' }]
  const donation_cycles = [
    { id: 1, value: 'monthly', label: 'Monthly' },
    { id: 2, value: 'quarterly', label: 'Quarterly' },
    { id: 3, value: 'yearly', label: 'Yearly' }]
  const params = useLocation();
  const [selectedCountry, setSelectedCountry] = useState()
  // const [selectedCountry, setSelectedCountry] = useState({ id: 160, name: 'Nigeria' })
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [selectedStatus, setSelectedStatus] = React.useState(null)
  
  let credentials = null
  const { makeDonation, getDonationStatus, getDonationTypes,getPatriots, getFundRaisings, payFundRaisings,getFundRaisingTypes } = useGenericActions()
  const { registerUser } = useAuth()
  const { data: statuses, isLoading: isStatusLoading } = useQuery(['donation-status'], getDonationStatus, {
    select: (res) => {
      const statusesOpt = res.data.donor_statuses.data?.map(item => {
        return { id: item.id, value: item.id, label: item.name }
      })
      return statusesOpt
    },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })

  const { data: fundraisingType } = useQuery(['fund-raising-types'], getFundRaisingTypes, {
    select: (res) => {
      const fundraisingTypeOpt = res.data.fund_raising_types?.map(item => {
        return { id: item.id, value: item.id, label: item.name }
      })
      return fundraisingTypeOpt
    },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })

  // console.log(categoryId)
  // = useQuery(['forum post', activityId], () => getAnActivity(activityId),
  const { data: donationCause } = useQuery(['donations',categoryId],()=> getDonationTypes(categoryId), {
    select: (res) => {
      const permOpt = res.data.donations.data.map(item => {
        return { id: item.id, value: item.id, label: (<ToolTips id={item.id} tooltip={item.description}><span>{item.tagline}</span></ToolTips>)
        }
      })

      return permOpt
    },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })

  const { data: patriot } = useQuery(['patriot'],()=> getPatriots(), {
    select: (res) => {
      return res.data.patriot.data
    },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })
  useEffect(() => {
    if (params?.search?.includes("trxref")) {
      notify('Donation sent successfully', { type: 'success', duration: 5000 })
    }
  }, [params.search])

  const { data: fundraiserCause } = useQuery(['fundraising', 'generic'], getFundRaisings, {
    select: (res) => {
      const permOpt = res.data.fund_raising.data.map(item => {
        return {
          id: item.id, value: item.id, label: (<ToolTips id={item.id} tooltip={item.description}><span>{item.name}</span></ToolTips>)
        }
      })

      return permOpt
    },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })

  const registerUserMutation = useMutation(registerUser, {
    onSuccess: (res) => {
      setAuth(res.data)
      setTimeout(() => {
        debugger;
        if (credentials.donation_group.value === 'fundraisers') {
          const fundData = {
            callbackUrl: `${window.location.origin}/donation`,
            fund_raising_id: credentials.donation_id,
            email: credentials.email,
            first_name: credentials.donor_name.split('')[0],
            last_name: credentials.donor_name.split('')[1],
            amount: credentials.amount,
            currency: params?.state?.currency || 'NGN',
          }
          fundraiserDonationMutation.mutate(fundData)
        } else {
          delete credentials.donation_group
          createDonationMutation.mutate(credentials)
        }

      }, 500);
    },
    onError: err => {
      if (err.includes('The email has already been taken') || JSON.stringify(err).toLowerCase().includes('integrity constraint')) {
        notify('Your details already exists please login to make a donation', { type: 'error', duration: 5000 })
      } else {
        notify(err, { type: 'error' })
      }
    }
  })

  const createDonationMutation = useMutation(makeDonation, {
    onSuccess: (res) => {
      window.open(res.data.authorization_url, '_self');
    },
    onError: err => {
      notify(err, { type: 'error' })
    }
  })


  const fundraiserDonationMutation = useMutation(payFundRaisings, {
    onSuccess: (res) => {
      window.open(res.data.authorization_url, '_self');
    },
    onError: err => {
      notify(err, { type: 'error' })
    }
  })

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      anonymous: false,
      first_name: userDetails?.first_name || '',
      last_name: userDetails?.last_name || '',
      email: userDetails?.email || '',
      phone_home: userDetails?.phone_home || '',
      phone_mobile: userDetails?.phone_mobile || '',
      dob_month: '',
      dob_day: '',
      address: userDetails?.address || '',
      country_id: '',
      state_id: '',
      city_id: '',
      status_id: '',
      amount: params?.state?.amount || '',
      donation_type: params?.state?.type ? donation_types[1] : '',
      donation_cause: '',
      donation_cycle: '',
      password: '',
      password_confirmation: '',
      donation_group: '',
    },
    onSubmit: (values) => {
      const _credentials = {
        donor_name: `${values.first_name} ${values.last_name}`,
        donor_email: values.email,
        donation_id: values.donation_cause.id,
        donor_phone_number: values.phone_mobile,
        donor_phone_home: values.phone_home,
        donor_status_id: 1,
        address: values.address,
        country_id: values.country_id,
        state_id: values.state_id,
        city_id: values.city_id,
        date_of_birth: `1970-${values.dob_month}-${values.dob_day}`,
        currency: params?.state?.currency || 'NGN',
        donation_cycle: values.donation_cycle || 'one-off',
        amount: values.amount,
        callbackUrl: `${window.location.origin}/donation`,
      }
      credentials = { ..._credentials, donation_group: values.donation_group, }
      const _credentialsToo = {
        ...values,
        date_of_birth: `1970-${values.dob_month}-${values.dob_day}`,
        password_confirmation: values.password,
        status_id: 1,
        callbackUrl: `${window.location.origin}/users/reset-password`
      }

      if (values.anonymous) {
        if (values.donation_group.value === 'fundraisers') {
          const fundData = {
            callbackUrl: `${window.location.origin}/donation`,
            fund_raising_id: values.donation_cause.id,
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            amount: values.amount,
            currency: params?.state?.currency || 'NGN',
          }
          fundraiserDonationMutation.mutate(fundData)
        } else {
          createDonationMutation.mutate(_credentials)
        }
      } else {
        registerUserMutation.mutate(_credentialsToo)
      }

    },
  })

  const formikRegular = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_home: '',
      phone_mobile: '',
      donation_cause: '',
      amount: '',
      donation_group: ''
    },
    onSubmit: (values) => {
      const _credentials = {
        donor_name: `${values.first_name} ${values.last_name}`,
        donor_email: values.email,
        donation_id: values.donation_cause.id,
        donor_phone_number: values.phone_mobile,
        donor_status_id: 2,
        donation_cycle: 'one-off',
        amount: values.amount,
        callbackUrl: `${window.location.origin}/complete/registration`,
      }
      credentials = { ..._credentials, donation_group: values.donation_group, }
      const _credentialsToo = {
        ...values,
        status_id: 1,
        callbackUrl: `${window.location.origin}/users/reset-password`
      }

        if (values.donation_group.value === 'fundraisers') {
          const fundData = {
            callbackUrl: `${window.location.origin}/complete/registration`,
            fund_raising_id: values.donation_cause.id,
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            amount: values.amount,
            phone_mobile: values.phone_mobile,
            phone_home: values.phone_home,
            currency: params?.state?.currency || 'NGN',
          }
          debugger
          fundraiserDonationMutation.mutate(fundData)
        } else {
          createDonationMutation.mutate(_credentials)
        }
      
    },
  })


  const formik2 = useFormik({
    validationSchema: validationSchemaDonations,
    enableReinitialize: true,
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_home: '',
      phone_mobile: '',
      donation_cause: '',
      amount: '',
      donation_group: ''
    },
    onSubmit: (values) => {
      const _credentials = {
        donor_name: `${values.first_name} ${values.last_name}`,
        donor_email: values.email,
        donation_id: values.donation_cause.id,
        donor_phone_number: values.phone_mobile,
        donor_status_id: 2,
        donation_cycle: 'one-off',
        amount: values.amount,
        callbackUrl: `${window.location.origin}/donation`
      }
      if (values.donation_group.value === 'fundraisers') {
        const fundData = {
          callbackUrl: `${window.location.origin}/donation`,
          fund_raising_id: values.donation_cause.id,
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name,
          amount: values.amount,
          currency: params?.state?.currency || 'NGN',
        }
        fundraiserDonationMutation.mutate(fundData)
      } else {
        createDonationMutation.mutate(_credentials)
      }
    },
  })
  const handleSelectchange = (e, name) => {
    formik.setFieldValue(name, e);
    setCategoryId(e.id);
  }

  const handleSelect2change = (e, name) => {
    formik2.setFieldValue(name, e);
    setCategoryId(e.id);
  }

  const handleSelectRegularchange = (e, name) => {
    formikRegular.setFieldValue(name, e);
    setCategoryId(e.id);
  }

  const default_returns = {
    formikRegular, handleSelectRegularchange,formik,categoryId,setCategoryId, selectedCity, setSelectedCity,fundraisingType, selectedState, setSelectedState, selectedCountry, setSelectedCountry
  }
  return {
    formik2, donation_types, registerUserMutation, isStatusLoading, createDonationMutation, selectedStatus, setSelectedStatus, statuses, donationCause,patriot, donation_cycles, donation_groups, fundraiserCause, handleSelect2change, handleSelectchange, ...default_returns
  }
}

export default useDonation
