import Modals from "components/Modal";
import TableTwo from "components/TableTwo";
import useDonorClassificationReport from "hook/reports/useDonorClassificationReport";
import { DONATION__CATEGORY_ANALYTICS } from "navigation/routes";
import { Link } from "react-router-dom";
import { startCase } from 'lodash'
import useDonationReport from "hook/reports/useDonationReport";
// useDonorClassificationReport
const DonorClassificationReport = () => {
  const { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns, data, setData, current, edit, setEdit } = useDonationReport()
  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Donor Classification Report</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item"><Link to='#'>Donations</Link></li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
          <Link className="btn btn-primary mb-2" to={`../${DONATION__CATEGORY_ANALYTICS}`} relative="path">View Analytics</Link>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Donations
          </div>
          <div className="card-body">
            <TableTwo 
              columns={columns}
              data={data || []}
              setData={setData}
              skipPageReset={skipPageReset}
              filterTable={data?.length}
              fileName='donor-classification-reports'
            />
          </div>
        </div>
      </div>
      <Modals show={open && current === 'view'}
        onHide={() => setOpen(false)} title='View Donation' size='md' >
        <dl className="striped">
          {currentData && Object.entries(currentData)?.map(([key, value]) =>
            typeof value !== 'string' && typeof value !== 'number' ? [] : <div key={key} className='row'>
              <dt className='col-4'>{`${startCase(key)} :`}</dt>
              {(!edit || key === 'id') && <dd className='col-8'>{value}</dd>}
              {edit && key !== 'id' && <div className="col-8"><input className="form-control" type="text" defaultValue={value} onChange={(e) => setCurrentData({ ...currentData, [key]: e.target.value })} /></div>}
            </div>)}
        </dl>
      </Modals>
    </>
  )
}

export default DonorClassificationReport
