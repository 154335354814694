// common pages
export const HOME = "/";
export const BLOG = "/blog";
export const ACTIVITY = "/activity";
export const PATRIOT = "/patriot";
export const SINGLEBLOG_ITEM = "/blog/:id";
export const BLOG_ROUTES = "/blog/*";
export const ADMIN_ROUTES = "/admin";
export const USER_ROUTES = "/users";
export const FORGOTPASSWORD = "/forgotpassword";
export const RESETPASSWORD = "/reset-password";
export const SIGNIN = "/signin";
export const ADMIN_SIGNIN = "/admin/signin";
export const SIGNUP = "/signup";
export const COMPLETE_REGISTRATION = "/complete/registration";
export const SIGNUP_NEXT = "/signup-next";
export const DONATION = "/donation";
export const LOGOUT = "logout";

// protected pages
export const DASHBOARD = "dashboard";
export const FORUM = "forum";
export const SINGLEFORUMPAGE = "forum/:forumId";
export const DONATE = "donate";
export const DONATION_HISTORY = "donation-history";
export const MY_ACTIVITY = "my-activity";
export const ACTIVITIES = "activities";
export const FUNDRAISER = "fundraiser";
export const FUNDRAISERMGT = "fundraiser-mgt";
export const MY_NETWORK = "my-network";
export const NETWORK_CHART = "network-chart";
export const LIVE_CHAT = "live-chat";
export const GROWTH = "growth";
export const REPORTS = "reports";
export const NOTIFICATION = "notification";
export const PROFILE = "settings";
export const CHART = "chart";
export const USER_LOG = "users-log";
export const ROLES = "roles";
export const ACTION_AID_USER_MGT = "action-aid-user";
export const ADMIN_LOGS = "admin-logs";
export const MEMBERSHIP = "membership";
export const CHAT = "chat";
export const DONATION_MGT = "donation-mgt";

export const DONATION_REPORT = "donation-report";
export const DONATION_ANALYTICS = "donation-analytics";

export const DEFAULT_DONATION = "donation-default";
export const DEFAULT_ANALYTICS = "default-analytics";

export const DEFAULT_CAMPAIGN = "campaign-default";

export const RECYCLE_DONATION = "donation-recycle";

export const ONE_OFF_DONATION = "donation-one-off";
export const ONE_OFF_ANALYTICS = "one-off-analytics";

export const RECYCLE_CAMPAIGN_DONATION = "donation-campaign-recycle";
export const ONE_OFF_CAMPAIGN_DONATION = "donation-campaign-one-off";

export const DONOR__REPORT = "donor-report";
export const DONOR__ANALYTICS = "donor-analytics";

export const DONATION__CATEGORY = "donation-category";
export const DONATION__CATEGORY_ANALYTICS = "donation-category-analytics";

export const FUNDRAISER__DONATION = "fundraiser";
export const FUNDRAISER__ANALYTICS = "fundraiser-analytics";

export const DONOR__STATUS = "donor-status";
export const DONOR__STATUS_ANALYTICS = "donor-status-analytics";

export const DONOR__SEGMENTATION = "donor-segmentation";

export const DONOR__CLASSIFICATION = "donor-classification";
export const DONOR__CLASSIFICATION_ANALYTICS = "donor-classification-analytics";

export const USER_REPORT = "user-reports";
export const USER_ANALYTICS = "user-analytics";

export const FORUM_MGT = "forum-mgt";
export const ADVERTS = "adverts";
export const NOTIFICATION_LOGS = "notification-logs";
export const NOTIFICATION_TEMPLATES = "notification-templates";
export const NOTIFICATION_BIRTHDAYS = "notification-birthdays";
export const NOTIFICATION_REMINDERS = "notification-reminders";
export const NOTIFICATION_ANIVERSARIES = "notification-aniversaries";
export const REPORTING = "reporting";
export const CRM = "crm";
export const TABLE = "table";
export const Charts = "charts";

// utilities
export const FUNDRAISER_TYPES = "fundraiser-types";
export const FORUM_CATEGORIES = "forum-categories";


// 404 page
export const ANY_ROUTE = "*";
