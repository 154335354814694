import useAxios from "./useAxios";

const useAdminActions = () => {
  const axios = useAxios()
  const format={
    method: 'GET',
    responseType: 'blob', // important
};
  return {
    getAllCategories,
    createACategory,
    updateACategory,
    deleteACategory,
    updateAdminProfile,
    getAdminProfile,
    updateAdminPassword,
    updateAdminProfilePicture,
    getAllPermissions,
    getAllRoles,
    createARole,
    updateARole,
    getARole,
    deleteARole,
    getAllAdminUsers,
    createAnAdminUser,
    updateAnAdminUser,
    deleteAnAdminUser,
    getAnAdminUser,
    assignRoleToAdminUser,
    getAllDonorStatus,
    getAllDonationCategories,
    getAllDonors,
    createADonor,
    updateADonor,
    messageADonor,
    deleteADonor,
    getADonor,
    getAllDonations,
    createADonation,
    updateADonation,
    deleteADonation,
    getADonation,
    getAllForumPosts,
    getAForumPost,
    createNewForumPosts,
    updateAForumPost,
    deleteAForumPost,
    togglePostActiveness,
    togglePostPubStatus,
    togglePostFeaturedStatus,
    togglePostApprovedStatus,
    getActivities,
    createNewActivity,
    getAnActivity,
    updateAnActivity,
    deleteAnActivity,
    toggleActivityActiveness,
    getFundraisingsAdmin,
    createNewFundraisingAdmin,
    getAFundraisingAdmin,
    updateAFundraisingAdmin,
    deleteAFundraisingAdmin,
    toggleFundraisingActiveness,
    uploadNotification,
    dashboard,
    dailyDonation,

    getAllAdminChat,
    deleteAdminChat,
    updateAdminChat,
    createAdminChat,
    getAllDonorDownload
    
  }

  

  function dashboard() {
    return axios.get(`/admin/transactions/dashboard`)
  }
  function dailyDonation() {
    return axios.get(`/admin/transactions/daily_donation`)
  }
  // Utilities
  function getAllCategories() {
    return axios.get(`/admin/categories?include=subCategories`)
  }

  function createACategory(payload) {
    return axios.post(`/admin/categories`, payload)
  }

  function updateACategory(payload) {
    return axios.put(`/admin/categories/${payload.id}`, payload)
  }

  function deleteACategory(payload) {
    return axios.delete(`/admin/categories/${payload.id}`, payload)
  }


  //PROFILE UPDATE
  function getAdminProfile() {
    return axios.get(`/admin/profile`)
  }

  function updateAdminProfile(payload) {
    return axios.post(`/admin/profile`, payload)
  }

  function updateAdminProfilePicture(payload) {
    return axios.post(`/admin/profile/update-profile-picture`, payload)
  }

  function updateAdminPassword(payload) {
    return axios.post(`/admin/change-password`, payload)
  }

  function uploadNotification(payload) {
    return axios.post(`/admin/users/message`, payload)
  }

  //PERMISSIONS
  function getAllPermissions() {
    return axios.get(`/admin/permissions`)
  }
  // ROLES
  function getAllRoles(page = 1) {
    return axios.get(`/admin/roles?page=${page}`)
  }

  function createARole(payload) {
    return axios.post(`/admin/roles`, payload)
  }

  function updateARole(payload) {
    return axios.post(`/admin/roles/${payload.id}`, payload)
  }

  function getARole(id) {
    return axios.get(`/admin/roles/${id}`)
  }

  function deleteARole(id) {
    return axios.delete(`/admin/roles/${id}`)
  }
  // AAID USER MANAGEMENT
  function getAllAdminUsers(page = 1) {
    return axios.get(`/admin/admins?page=${page}`)
  }

  function createAnAdminUser(payload) {
    return axios.post(`/admin/admins`, payload)
  }

  function updateAnAdminUser(payload) {
    return axios.post(`/admin/admins/${payload.id}`, payload)
  }

  function getAnAdminUser(id) {
    return axios.get(`/admin/admins/${id}?include=roles`)
  }

  function assignRoleToAdminUser({ id, role_id }) {
    return axios.post(`/admin/admins/${id}/assign-role`, { role_id })
  }

  function deleteAnAdminUser(id) {
    return axios.delete(`/admin/admins/${id}`) 
  }

  //DONATION MANAGEMENT
  function getAllDonationCategories() {
    return axios.get(`/admin/donation-categories`)
  }

  function getAllDonorStatus() {
    return axios.get(`/admin/donor-status`)
  }

  function getAllDonor344s(page = 1, search, level) {
    if(level!= null && search!= null){
      return axios.get(`/admin/users?page=${page}&filter[starts_level]=${level}&filter[last_name]=${search}&include=country,state,city,donorStatus,category`);
    }
    else if(level!= null){
      return axios.get(`/admin/users?page=${page}&filter[starts_level]=${level}&include=country,state,city,donorStatus,category`);
    }
    else if(search!= null){
      return axios.get(`/admin/users?page=${page}&filter[first_name]=${search}&include=country,state,city,donorStatus,category`);
    }
    else
    return axios.get(`/admin/users?page=${page}&include=country,state,city,donorStatus,category`);
  }

  function getAllDonors(page=1, search, level,state,lga,from,to,pagesize=100,status='') {
    return axios.get(`/admin/users?page=${page}&per_page=${pagesize}&filter[category_id]=${level}&filter[city_id]=${lga}&filter[state_id]=${state}&filter[status_id]=${status}&filter[search]=${search}&filter[date][]=${from},${to}&include=country,state,city,donorStatus,category`);
  }

  function getAllDonorDownload(page=1, search, level,state,lga,from,to) {
      return axios.get(`/admin/users?page=${page}&download=1&filter[category_id]=${level}&filter[city_id]=${lga}&filter[state_id]=${state}&filter[search]=${search}&filter[date][]=${from},${to}&include=country,state,city,donorStatus,category`, format);
  }

  function createADonor(payload) {
    return axios.post(`/admin/users`, payload)
  }

  function updateADonor(payload) {
    return axios.post(`/admin/users/${payload.id}`, payload)
  }

  function messageADonor(payload) {
    return axios.post(`/admin/users/${payload.id}/message`, payload)
  }

  function getADonor(id) {
    return axios.get(`/admin/users/${id}?include=country,state,city,category`)
  }

  function deleteADonor(id) {
    return axios.delete(`/admin/users/${id}`)
  }

  function getAllDonations(page = 1) {
    return axios.get(`/admin/donations?page=${page}`)
  }

  function createADonation(payload) {
    return axios.post(`/admin/donations`, payload)
  }

  function updateADonation(payload) {
    return axios.post(`/admin/donations/${payload.id}`, payload)
  }

  function getADonation(id) {
    return axios.get(`/admin/donations/${id}`)
  }

  function deleteADonation(id) {
    return axios.delete(`/admin/donations/${id}`)
  }

  // Forum Post
  function getAllForumPosts() {
    return axios.get(`/admin/forums?include=categories,tags`)
  }
  function createNewForumPosts(payload) {
    return axios.post(`/admin/forums`, payload)
  }

  function getAForumPost(id) {
    return axios.get(`/admin/forums/${id}?include=categories,comments`)
  }

  function updateAForumPost(payload) {
    return axios.post(`/admin/forums/${payload.get('id')}`, payload)
  }

  function deleteAForumPost({ id }) {
    return axios.delete(`/admin/forums/${id}`)
  }

  function togglePostApprovedStatus({ id }) {
    return axios.patch(`/admin/forums/${id}/toggle-approval`)
  }

  function togglePostActiveness({ id }) {
    return axios.patch(`/admin/forums/${id}/toggle-active`)
  }

  function togglePostPubStatus({ id }) {
    return axios.patch(`/admin/forums/${id}/toggle-published`)
  }

  function togglePostFeaturedStatus({ id }) {
    return axios.patch(`/admin/forums/${id}/toggle-featured`)
  }
  // Activities
  function getActivities() {
    return axios.get(`/admin/activities`)
  }
  function createNewActivity(payload) {
    return axios.post(`/admin/activities`, payload)
  }

  function getAnActivity(id) {
    return axios.get(`/admin/activities/${id}?include=user`)
  }

  function updateAnActivity(payload) {
    return axios.post(`/admin/activities/${payload.get('id')}`, payload)
  }

  function deleteAnActivity({ id }) {
    return axios.delete(`/admin/activities/${id}`)
  }
  function toggleActivityActiveness({ id }) {
    return axios.patch(`/admin/activities/${id}/toggle-active`)
  }
  // FundRaising
  function getFundraisingsAdmin() {
    return axios.get(`/admin/fund-raisings?include=user,country,state,city`)//TODO include type
  }
  function createNewFundraisingAdmin(payload) {
    return axios.post(`/admin/fund-raisings`, payload)
  }

  function getAFundraisingAdmin(id) {
    return axios.get(`/admin/fund-raisings/${id}?include=user,country,state,city`)
  }

  function updateAFundraisingAdmin(payload) {
    return axios.post(`/admin/fund-raisings/${payload.get('id')}`, payload)
  }

  function deleteAFundraisingAdmin({ id }) {
    return axios.delete(`/admin/fund-raisings/${id}`)
  }
  function toggleFundraisingActiveness({ id }) {
    return axios.patch(`/admin/fund-raisings/${id}/toggle-active`)
  }

  function createAdminChat(payload) {
    return axios.post('/admin/room', payload);
  }

  function getAllAdminChat(page = 1) {
    return axios.get(`/admin/room?page=${page}`);
  }

  function deleteAdminChat({ id }) {
    return axios.delete(`/admin/room/${id}/delete`);
  }

  function updateAdminChat(payload) {
    const { data, id } = payload
    return axios.post(`/admin/room/${id}`, data);
  }
}

export default useAdminActions;
