import useAxios from "./useAxios";
const useGenericActions = () => {
  const axios = useAxios()

  return {
    payFundRaisings,
    getFundRaisings,
    getFundRaisingTypes,
    updateFundRaisingTypes,
    addFundRaisingTypes,
    deleteAFundRaisingTypes,
    getActivities,
    getAnActivity,
    getPosts,
    getAPost,
    addAComment,
    getAllCategories,
    getACategory,
    makeDonation,
    getDonationTypes,
    getDonationStatus,
    getPatriots
  }

  function payFundRaisings(payload) {
    return axios.post(`/generic/fund-raisings/paystack/payment-intent`, payload);
  }

  function getFundRaisings() {
    return axios.get(`/generic/fund-raisings`);
  }

  function getFundRaisingTypes() {
    return axios.get(`/generic/fund-raising-types`);
  }
  function updateFundRaisingTypes(payload) {
    return axios.put(`/generic/fund-raising-types/${payload.id}`, payload)
  }

  function deleteAFundRaisingTypes(payload) {
    return axios.delete(`/generic/fund-raising-types/${payload.id}`, payload)
  }
  function addFundRaisingTypes(payload) {
    return axios.post(`/generic/fund-raising-types`, payload)
  }
  

  function getActivities() {
    return axios.get(`/generic/activities`);
  }

  function getAnActivity(id) {
    return axios.get(`/generic/activities/${id}`);
  }

  function getPosts() {
    return axios.get(`/generic/forums?include=comments,comments.user,comments.replies,comments.replies.user`);
  }

  function getAPost(id) {
    return axios.get(`/generic/forums/${id}?include=comments,comments.user,comments.replies,comments.replies.user`);
  }

  function addAComment({ postId, ...payload }) {
    const decide = JSON.parse(localStorage.getItem('recoil-persist'));
    if(decide?.auth?.admin){
      return axios.post(`/generic/forums/${postId}/commentadmins`, payload);
    }
    else if(decide?.auth?.user){
    return axios.post(`/generic/forums/${postId}/comments`, payload);
    }
    else{
      return axios.post(`/generic/forums/${postId}/comments/general`, payload);
    }
  }

  function showAComment({ postId, commentId }) {
    return axios.get(`/generic/forums/${postId}/comments/${commentId}`);
  }

  function updateAComment() {
    return axios.get(`/generic/forums?include=comments`);
  }

  function deleteAComment(id) {
    return axios.get(`/generic/forums/${id}`);
  }

  function getAllCategories() {
    return axios.get(`/generic/categories`);
  }

  function getACategory({ id }) {
    return axios.get(`/generic/categories/${id}`);
  }

  function makeDonation(payload) {
    return axios.post(`/generic/donations`, payload);
  }

  function getDonationTypes(id) {
    console.log(id);
    return axios.get(`/generic/donations?filter[category_id]=${id}`,);
  }

  function getPatriots() {
    return axios.get('/generic/patriots');
  }

  function getDonationStatus() {
    return axios.get(`/generic/donor-status`,);
  }
}

export default useGenericActions;
