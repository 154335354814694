import CitySelect from 'components/common/Selects/CitySelect';
import CountrySelect from 'components/common/Selects/CountrySelect';
import StateSelect from 'components/common/Selects/StateSelect';
import EnhancedTable from 'components/EnhancedTable';
import Input from 'components/innerComponents/Input';
import LoadingIndicator from 'components/LoadingIndicator';
import Modals from 'components/Modal';
import useDonationManagement from 'hook/useDonationManagement';
import { Suspense, useState } from 'react';
import { getNames, scrollToErrors } from 'utils/functions';
import Select from '../../../components/innerComponents/Select';
import { FORUM } from '../../../navigation/routes';
import { startCase } from 'lodash';
import { format } from 'date-fns';
import { donation_groups, excludedFields,excludedMessageFields } from 'utils/constants';
import ButtonWithTips from 'components/ButtonWithTips';
import { useRecoilValue } from 'recoil';
import { getCauseCategoryById } from 'hook/state/generic';
import SelectBox from 'components/common/Selects/SelectBox';
import useDonation from 'hook/useDonation';
import MembershipEnhancedTable from 'components/MembershipEnhancedTable';

const DonationMgt = () => {
  
  const donation_types = [
    { id: 1, value: 'one-off', label: 'One Off' },
    { id: 2, value: 'recurrent', label: 'Recurrent' }];
    const donation_cycles = [
      { id: 1, value: 'monthly', label: 'Monthly' },
      { id: 2, value: 'quarterly', label: 'Quarterly' },
      { id: 3, value: 'yearly', label: 'Yearly' }]
  const user_level = [      
        { id: '', value: 'All', label: 'All' },
        { id: 1, value: 'Member', label: 'Member' },
        { id: 2, value: 'Champion', label: 'Champion' },
        { id: 3, value: 'Patriot', label: 'Patriot' }
        
      ];  
  const { 
    isStatusLoading,
    isCategoriesLoading,
    allDonations,
    singleDonation,
    createDonationMutation,
    updateDonationMutation,
    deleteDonationMutation,
    page2,
    setPage2,
    selectedCycle,
    setSelectedCycle,
    selectedCategory,
    setSelectedCategory,
    selectedStatus,
    setSelectedStatus,
    donationType,
    setDonationType,
    formik2,
    allDonors,
    messageDonor,
    singleDonor,
    createDonorMutation,
    updateDonorMutation,
    messageDonorMutation,
    deleteDonorMutation,
    categories,
    statuses,
    formik,
    makeDonationMutation,
    updateMyData,
    columns,
    donationColumn,
    open,
    setOpen,
    edit,
    setEdit,
    current,
    setCurrent,
    currentData,
    setCurrentData,
    skipPageReset,
    page,
    setPage,
    donors,
    setDonors,
    donations,
    setDonations,
    selectedCity,
    setSelectedCity,
    selectedDonor,
    setSelectedDonor,
    selectedReferral,
    setSelectedReferral,
    selectedState,
    setSelectedState,
    selectedCountry,
    setSelectedCountry,
    handleDonationTypeChange,
    formik3,
    donationTypes,
    donorOpts,
    setSearch,
    setSearchLevel,
    setSearchStatus
         
  } = useDonationManagement();
const{
  fundraiserCause,
  donationCause,
  handleSelect2change,
  categoryId,
  setCategoryId,
  fundraisingType
} = useDonation();

const [selectedType, setSelectedType] = useState(null)
  const causes = useRecoilValue(getCauseCategoryById(selectedCategory));
  return (
    <>
      <div className="container-fluid px-4">
        <div className="d-sm-flex align-items-start justify-content-between mt-4">
          <div>
            <h1>Donation</h1>
            <ol className="breadcrumb mb-2">
              <li className="breadcrumb-item">
                <a href={FORUM}>Donation</a>
              </li>
              <li className="breadcrumb-item active">All</li>
            </ol>
          </div>
          <div className="d-flex flex-wrap align-items-center justify-content-sm-end gap-2 mb-2">
            <ButtonWithTips
              id="addDonation"
              tips="Add a new donation"
              btnProps={{
                className: 'btn btn-primary',
                onClick: () => {
                  setOpen(true);
                  setCurrent('addDonation');
                },
              }}
            >
              Add Donation Type
            </ButtonWithTips>

            <ButtonWithTips
              id="addDonor"
              tips="Create a new donor"
              btnProps={{
                className: 'btn btn-info',
                onClick: () => {
                  setOpen(true);
                  setCurrent('addDonor');
                },
              }}
            >
              Create Donors
            </ButtonWithTips>

            <ButtonWithTips
              id="makeDonation"
              tips="Make donation on behalf of a donor"
              btnProps={{
                className: 'btn btn-secondary',
                onClick: () => {
                  setOpen(true);
                  setCurrent('makeDonation');
                },
                // disabled: true,
              }}
            >
              Make Donation
            </ButtonWithTips>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Donors
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-2"  style={{width:'20%'}}>
              <input type='text' placeholder='Global Search Record' className="form-control"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              />
              </div>
              <div className="col-2" style={{width:'20%'}}>
            <SelectBox
                    selectName="donation_group"
                    selectLabel={`Donation category`}
                    style={{width: '17%'}}
                    className="form-control"
                    isMulti={false}
                    itemList={user_level}
                    onChange={(e) => {
                       setSearchLevel(e.id);
                    }}
                    selectOption={`Select Level`}
                   
                  />
            </div>
            <div className="col-2" style={{width:'20%'}}>
            {/* <SelectBox
                    selectName="status_id"
                    selectLabel={`Donation Status`}
                    style={{width: '17%'}}
                    className="form-control"
                    isMulti={false}
                    itemList={statuses?.data?.donor_statuses?.data}
                    onChange={(e) => {
                      setSearchStatus(e.target.value);
                    }}
                    selectOption={`Select Level`}
                   
                  /> */}
            <Select
                className="form-select"
                // selected={JSON.parse(selectedStatus)}
                handleChange={(e) => {
                  var id=JSON.parse(e.target.value)?.id
                  setSearchStatus(id);
               }}
                // handleChange={(e) => {
                //   formik.setFieldValue(
                //     'status_id',
                //     JSON.parse(e.target.value)?.id
                //   );
                //   setSelectedStatus(e.target.value);
                // }}
                options={statuses?.data?.donor_statuses?.data}
              />
            </div>
            </div>
          </div>
          <div className="card-body">
            <MembershipEnhancedTable
              columns={columns}
              data={allDonors?.data?.data?.users?.data || []}
              setData={setDonors}
              skipPageReset={skipPageReset}
              lastPage={allDonors?.data?.data?.users?.last_page}
              currentPage={page}
              setCurrentPage={setPage}
              isFetching={allDonors?.isFetching || allDonors?.isLoading}
            />
          </div>
        </div>
        <div className="card mb-4">
          <div className="card-header">
            <i className="fas fa-table me-1"></i>
            Donation Types
          </div>
          <div className="card-body">
            <EnhancedTable
              columns={donationColumn}
              data={allDonations?.data?.data?.donations?.data || []}
              setData={setDonations}
              skipPageReset={skipPageReset}
              lastPage={allDonations?.data?.data?.donations?.last_page}
              currentPage={page2}
              setCurrentPage={setPage2}
              isFetching={allDonations?.isFetching || allDonations?.isLoading}
            />
          </div>
        </div>
      </div>

      {/* Addition modals */}
      <Modals
        show={open && current === 'addDonor'}
        onHide={() => setOpen(false)}
        size={'md'}
        title="Create new donor"
      >
        <form onSubmit={formik.handleSubmit}>
          <Input
            name="first_name"
            value={formik.values.first_name}
            type="text"
            placeholder="First Name"
            label="First Name"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={
              formik.errors.first_name && formik.touched.first_name
                ? formik.errors.first_name
                : null
            }
          />
          <Input
            name="last_name"
            value={formik.values.last_name}
            type="text"
            placeholder="Last Name"
            label="Last Name"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={
              formik.errors.last_name && formik.touched.last_name
                ? formik.errors.last_name
                : null
            }
          />
          <Input
            name="email"
            value={formik.values.email}
            type="email"
            placeholder="Email"
            label="Email"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={
              formik.errors.email && formik.touched.email
                ? formik.errors.email
                : null
            }
          />
          <Input
            name="phone_mobile"
            value={formik.values.phone_mobile}
            type="tel"
            placeholder="Phone Number 1(Mobile)"
            label="Phone Number 1(Mobile)"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={
              formik.errors.phone_mobile && formik.touched.phone_mobile
                ? formik.errors.phone_mobile
                : null
            }
          />
          <Input
            name="phone_home"
            value={formik.values.phone_home}
            type="tel"
            placeholder="Phone Number 2(Home)"
            label="Phone Number 2(Home)"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={
              formik.errors.phone_home && formik.touched.phone_home
                ? formik.errors.phone_home
                : null
            }
          />
          <Input
            name="date_of_birth"
            value={formik.values.date_of_birth}
            type="date"
            placeholder="Date of Birth"
            label="Date of Birth"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={
              formik.errors.date_of_birth && formik.touched.date_of_birth
                ? formik.errors.date_of_birth
                : null
            }
          />
          <div className="mb-3">
            <div className="form-floating">
              <Suspense fallback={<LoadingIndicator />}>
                <CountrySelect
                  selected={selectedCountry}
                  handleChange={(e) => {
                    setSelectedCountry(JSON.parse(e.target.value));
                    formik.setFieldValue(
                      'country_id',
                      JSON.parse(e.target.value)?.id
                    );
                  }}
                />
              </Suspense>
              <label htmlFor="country">Country </label>
            </div>
            {formik.errors.country_id && formik.touched.country_id && (
              <small className="error">{formik.errors.country_id} </small>
            )}
          </div>
          <div className="mb-3">
            <div className="form-floating">
              <Suspense fallback={<LoadingIndicator />}>
                <StateSelect
                  selectedCountry={selectedCountry}
                  selected={selectedState}
                  handleChange={(e) => {
                    setSelectedState(JSON.parse(e.target.value));
                    formik.setFieldValue(
                      'state_id',
                      JSON.parse(e.target.value)?.id
                    );
                  }}
                />
              </Suspense>
              <label htmlFor="state">State</label>
            </div>
            {formik.errors.state_id && formik.touched.state_id && (
              <small className="error">{formik.errors.state_id} </small>
            )}
          </div>
          <div className="mb-3">
            <div className="form-floating">
              <Suspense fallback={<LoadingIndicator />}>
                <CitySelect
                  selectedState={selectedState}
                  selected={selectedCity}
                  handleChange={(e) => {
                    setSelectedCity(JSON.parse(e.target.value));
                    formik.setFieldValue(
                      'city_id',
                      JSON.parse(e.target.value)?.id
                    );
                  }}
                />
              </Suspense>
              <label htmlFor="city">City/town</label>
            </div>
            {formik.errors.city_id && formik.touched.city_id && (
              <small className="error">{formik.errors.city_id} </small>
            )}
          </div>

          <Input
            name="address"
            value={formik.values.address}
            type="text"
            placeholder="Address"
            label="Address"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={
              formik.errors.address && formik.touched.address
                ? formik.errors.address
                : null
            }
          />
          <div className="mb-3">
            <div className="form-floating">
              <Select
                id="status"
                className="form-select"
                selected={JSON.parse(selectedStatus)}
                handleChange={(e) => {
                  formik.setFieldValue(
                    'status_id',
                    JSON.parse(e.target.value)?.id
                  );
                  setSelectedStatus(e.target.value);
                }}
                options={statuses?.data?.donor_statuses?.data}
              />
              <label htmlFor="status">Status</label>
            </div>
            {formik.errors.status_id && formik.touched.status_id && (
              <small className="error">{formik.errors.status_id} </small>
            )}
          </div>
          <div className="form-floating mb-3">
            <input
              className="form-control"
              id="referral"
              name="referral"
              type="text"
            />
            <label htmlFor="referral">Referred by </label>
          </div>
          <div className="form-floating mb-3">
            <Select
              id="donationType"
              className="form-select"
              selected={donationType}
              handleChange={(e) => {
                debugger
                formik.setFieldValue(
                  'donation_type',
                  JSON.parse(e.target.value)?.id
                );
                setDonationType(JSON.parse(e.target.value));
              }}
              options={donationTypes}
            />
            <label htmlFor="donationType">Donation type </label>
          </div>
          <div className="flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={createDonorMutation.isLoading}
              onClick={() => scrollToErrors(formik.errors)}
            >
              {createDonorMutation.isLoading && <LoadingIndicator />} Create
            </button>
          </div>
        </form>
      </Modals>
      <Modals
        show={open && current === 'addDonation'}
        onHide={() => setOpen(false)}
        size={'md'}
        title="Add new donation"
      >
        <form onSubmit={formik2.handleSubmit}>
          <div className="mb-3">
            <div className="form-floating">
              <Select
                name="category_id"
                className="form-select"
                selected={selectedCategory}
                handleChange={(e) => {
                  formik2.setFieldValue(
                    'category_id',
                    JSON.parse(e.target.value)?.id
                  );
                  setSelectedCategory(JSON.parse(e.target.value));
                }}                
                options={categories}
              />              
              <label htmlFor="category">Category</label>
            </div>
            {formik2.errors.category_id && formik2.touched.category_id && (
              <small className="error">{formik2.errors.category_id}</small>
            )}
          </div>
          <Input
            name="tagline"
            value={formik2.values.tagline}
            type="text"
            placeholder="Tagline"
            label="Name"
            handleChange={formik2.handleChange}
            handleBlur={formik2.handleBlur}
            error={
              formik2.errors.tagline && formik2.touched.tagline
                ? formik2.errors.tagline
                : null
            }
          />

          <Input
            name="description"
            value={formik2.values.description}
            type="text"
            placeholder="Description"
            label="Description"
            handleChange={formik2.handleChange}
            handleBlur={formik2.handleBlur}
            error={
              formik2.errors.description && formik2.touched.description
                ? formik2.errors.description
                : null
            }
          />
         
          <Input
            name="amount"
            value={formik2.values.amount}
            type="number"
            placeholder="Amount"
            label="Amount"
            handleChange={formik2.handleChange}
            handleBlur={formik2.handleBlur}
            error={
              formik2.errors.amount && formik2.touched.amount
                ? formik2.errors.amount
                : null
            }
          />
          <Input
            name="start_date"
            value={formik2.values.start_date}
            type="date"
            placeholder="Donation Start Date"
            label="Start Date"
            handleChange={formik2.handleChange}
            handleBlur={formik2.handleBlur}
            error={
              formik2.errors.start_date && formik2.touched.start_date
                ? formik2.errors.start_date
                : null
            }
          />
          <Input
            name="end_date"
            value={formik2.values.end_date}
            type="date"
            placeholder="Donation End Date"
            label="End Date"
            handleChange={formik2.handleChange}
            handleBlur={formik2.handleBlur}
            error={
              formik2.errors.end_date && formik2.touched.end_date
                ? formik2.errors.end_date
                : null
            }
          />
          {/* <div className="mb-3">
            <label htmlFor="media">Media</label>
            <input
              type="file"
              className="form-control"
              id="media"
              aria-describedby="media"
              aria-label="Upload"
            />
          </div> */}
          <div className="flex justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={createDonationMutation.isLoading}
            >
              
              {createDonationMutation.isLoading && <LoadingIndicator />} Create
            </button>
          </div>
        </form>
      </Modals>
      <Modals
        show={open && current === 'messageDonation'  && messageDonor}
        onHide={() => setOpen(false)}
        size={'md'}
        title="Message Donor"
        footer={
          <button
            type="button"
            className="btn btn-primary"
            disabled={messageDonor?.isLoading}
            onClick={() => {              
                updateMyData('message_donor');
              
            }}
          >
            {messageDonorMutation.isLoading && <LoadingIndicator />}
            Send Message
          </button>
        }
      >
       <form onSubmit={formik2.handleSubmit}>
        {messageDonor?.data &&
        Object.entries(messageDonor?.data)?.map(([key, value]) => 
          excludedFields.includes(key) ? (
            []
          ):  key === 'phone_mobile'  ?(
        
            <div key={key} className="row">
                  <dt className="col-4">Phone Number</dt>
                  <dd className="col-8">
                  <input
                    className="form-control"
                    type='text'
                    defaultValue={value}
                    readOnly
                    onChange={(e) =>
                      setCurrentData({
                        ...currentData,
                        [key]: e.target.value,
                      })
                    }
                  />
                  </dd>
                </div>
            
         
          ): key === 'email'?(
      

            <div className="row">
                  <dt className="col-4">Email</dt>
                  <dd className="col-8">
                  <input
                      className="form-control"
                      type='email'
                      defaultValue={value}
                      readOnly
                      onChange={(e) =>
                        setCurrentData({
                          ...currentData,
                          [key]: e.target.value,
                        })
                      }
                    />
                  </dd>
                </div>
            
          ):(''))}
             
             <div className="row">
                  <dt className="col-4">Message</dt>
                  <dd className="col-8">
                  <textarea
                    name="message"                   
                    placeholder="message"
                    label="message"
                    style={{height: 100, width:'100%'}}
                    onChange={(e) =>
                      setCurrentData({
                        ...currentData,
                        message: e.target.value,
                      })
                    }
                  />
                  </dd>
                </div>
             
          
          </form>
      
    
    
        
      </Modals>
      
      <Modals
        show={open && current === 'makeDonation'}
        onHide={() => setOpen(false)}
        size={'md'}
        title="Make donation"
      >
        <form onSubmit={formik3.handleSubmit}>
          <div className="mb-3">
            <div className="form-floating">
              <Select
                id="donor"
                name={'donor_name'}
                className="form-select"
                selected={JSON.parse(selectedDonor)}
                handleChange={(e) => {
                  formik3.setFieldValue(
                    'donor_user',
                    JSON.parse(e.target.value)?.id
                  );
                  formik3.setFieldValue(
                    'donor_name',
                    JSON.parse(e.target.value)?.label
                  );
                  formik3.setFieldValue(
                    'donor_email',
                    JSON.parse(e.target.value)?.email
                  );
                  formik3.setFieldValue(
                    'donor_phone_number',
                    JSON.parse(e.target.value)?.phone
                  );
                  setSelectedDonor(e.target.value);
                }}
                options={donorOpts}
              />
              <label htmlFor="donor">Select Donor</label>
            </div>
            {formik3.errors.donor_name &&
              formik3.touched.donor_name && (
                <small className="error">
                  {formik3.errors.donor_name}{' '}
                </small>
              )}
          </div>
          <div className="mb-3">
            <div className="form-floating">
              <Select
                id="status"
                name={'donor_status_id'}
                className="form-select"
                selected={JSON.parse(selectedStatus)}
                handleChange={(e) => {
                  formik3.setFieldValue(
                    'donor_status_id',
                    JSON.parse(e.target.value)?.id
                  );
                  setSelectedStatus(e.target.value);
                }}
                options={statuses?.data?.donor_statuses?.data}
              />
              <label htmlFor="status">Status</label>
            </div>
            {formik3.errors.donor_status_id &&
              formik3.touched.donor_status_id && (
                <small className="error">
                  {formik3.errors.donor_status_id}{' '}
                </small>
              )}
          </div>
          <div className="form-floating mb-3">
            <input
              name="email"
              className="form-control"
              id="email"
              value={JSON.parse(selectedDonor)?.email || ''}
              type="text"
              disabled
              placeholder="donor email auto filled"
            />

           
            <label htmlFor="email">Email(Autofilled)</label>
          </div>
          <div className="mb-3">
            <div className="form-floating">
                {/* <Select
                  name="category_id"
                  className="form-select"
                  selected={selectedCategory}
                   itemList={donation_groups}
                  handleChange={(e) => {
                    formik3.setFieldValue(
                      'category_id',
                      JSON.parse(e.target.value)?.id
                    );
                    setSelectedCategory(JSON.parse(e.target.value));
                  }}                
                  options={categories}
                />               */}

                  <SelectBox
                    selectName="donation_group"
                    selectLabel={`Donation category`}
                    itemList={donation_groups}
                    isMulti={false}
                    value={formik3.values.donation_group}
                    onChange={(e,name) => {
                      formik3.setFieldValue(name, e);
                      setCategoryId(e.id);
                      // setSelectedCategory(JSON.parse(e.value));
                    }}           
                    // onChange={handleSelectRegularchange}
                    selectOption={`Select donation category`}
                    error={
                      formik3.errors.donation_group &&
                        formik3.touched.donation_group
                        ? formik3.errors.donation_group
                        : null
                    }
                  />
                {/* <label htmlFor="category">Category</label> */}
              </div>
              {formik3.errors.category_id && formik3.touched.category_id && (
                <small className="error">{formik3.errors.category_id}</small>
              )}
          </div>
          

          <div className="mb-3">
            <div className="form-floating">
            
            <SelectBox
                      selectName="donation_id"
                      selectLabel={`Donation Cause`}
                     
                      itemList={
                        formik3.values?.donation_group?.value === 'fundraisers'
                          ? fundraiserCause
                          : formik3.values?.donation_group?.value === 'activity'|| 
                          formik3.values?.donation_group?.value === 'challenge'|| formik3.values?.donation_group?.value === 'campaign'
                            ? donationCause
                            : []
                      }
                      isMulti={false}
                      value={formik3.values.donation_id}
                      // onChange={handleDonationTypeChange}          
                      onChange={(e,name) => {
                       
                        formik3.setFieldValue(name, e);
                        //setCategoryId(e.id);
                        // setSelectedCategory(JSON.parse(e.value));
                      }}              
                      selectOption={`Select cause`}
                      error={
                        formik3.errors.donation_id &&
                          formik3.touched.donation_id
                          ? formik3.errors.donation_id
                          : null
                      }
                    />

{/* <Select
                id="donation_id"
                name={'donation_id'}
                className="form-select"
                selected={JSON.parse(selectedStatus)}
                handleChange={(e) => {
                  formik3.setFieldValue(
                    'donation_id',
                    JSON.parse(e.target.value)?.id
                  );
                  //setSelectedStatus(e.target.value);
                }}
                options={statuses?.data?.donor_statuses?.data}
              /> */}
              {/* <Select
                id="donationType"
                name={'donation_id'}
                className="form-select"
                selected={selectedCategory}
                handleChange={(e) => {
                  formik3.setFieldValue(
                    'donation_id',
                    JSON.parse(e.target.value)?.id
                  );
                  setSelectedCategory(JSON.parse(e.target.value));
                }}
                options={causes}

              /> */}
             
              {/* <label htmlFor="donationType">Donation type</label> */}
            </div>
            {formik3.errors.donation_id && formik3.touched.donation_id && (
              <small className="error">{formik3.errors.donation_id} </small>
            )}
          </div>

          <div className="mb-3">
            <div className="form-floating">
                <Select id="funding_type"
                name={'funding_type'}
                className="form-select" selected={selectedCycle} 
                handleChange={(e) => {
                  formik3.setFieldValue(
                    'funding_type',
                    JSON.parse(e.target.value)?.value
                  );                  
                    setSelectedCycle(JSON.parse(e.target.value))
                }} options={fundraisingType} />
                <label htmlFor="funding_type">Funding Type</label>                
            </div>
            
              {formik3.errors.funding_type &&
              formik3.touched.funding_type && (
                <small className="error">
                  {formik3.errors.funding_type}{' '}
                </small>
              )}
          </div>
          <div className="mb-3">
            <div className="form-floating">
              <Select
                id="donor"
                name={'referral'}
                className="form-select"
                selected={JSON.parse(selectedReferral)}
                handleChange={(e) => {
                 
                  formik3.setFieldValue(
                    'referral',
                    JSON.parse(e.target.value)?.email
                  );
                  
                  setSelectedReferral(e.target.value);
                }}
                options={donorOpts}
              />
              <label htmlFor="referral">Select Referral</label>
            </div>
            {formik3.errors.referral &&
              formik3.touched.referral && (
                <small className="error">
                  {formik3.errors.referral}{' '}
                </small>
              )}
          </div>
          <div className="mb-3">
            <div className="form-floating">
                <Select id="d_type"
                name={'donation_type'}
                className="form-select" selected={selectedType} 
                handleChange={(e) => {
                  formik3.setFieldValue(
                    'donation_type',
                    JSON.parse(e.target.value)?.value
                  );                  
                    setSelectedType(JSON.parse(e.target.value))
                }} options={donation_types} />
                <label htmlFor="d_cycle">Donation Type</label>                
            </div>
            
              {formik3.errors.donation_type &&
              formik3.touched.donation_type && (
                <small className="error">
                  {formik3.errors.donation_type}{' '}
                </small>
              )}
          </div>

          {selectedType?.value === 'recurrent' && <div className="form-floating mb-3">
                                        <Select id="donation_cycle" className="form-select" selected={selectedCycle} handleChange={(e) => {
                                            formik3.setFieldValue('donation_cycle', JSON.parse(e.target.value)?.value); setSelectedCycle(JSON.parse(e.target.value))
                                        }} options={donation_cycles} />
                                        <label htmlFor="donation_cycle">Donation Cycles</label>
                                    </div>}

          {!formik3.values.donor_phone_number &&
              <div className=" mb-3">
                  <div className="form-floating">
                      <input type="text" 
                      className="form-control" name="donor_phone_number" 
                      aria-label="donor_phone_number"  
                      value={JSON.parse(selectedDonor)?.phone || ''}
                      aria-describedby="basic-addon1" />
                      <label htmlFor="phoneNumber">Phone number</label>
                  </div>
              </div>
          }
          <div className="mb-3">
              <div className="input-group">
                  <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">₦</span>
                  </div>
                  <input type="text" className="form-control" name="amount" placeholder="5000" aria-label="amount" value={formik3.values.amount} onChange={formik3.handleChange} onBlur={formik3.handleBlur} aria-describedby="basic-addon1" />
              </div>
              {formik3.errors.amount && formik3.touched.amount && <small className="error">{formik3.errors.amount} </small>}
          </div>
          <div className="flex justify-content-end">
            <button
              type="submit" 
              className="btn btn-primary"
              disabled={makeDonationMutation.isLoading}
            >
              {makeDonationMutation.isLoading && <LoadingIndicator />} Donate
            </button>
          </div>
        </form>
      </Modals>

      {/* View Modals */}
      <Modals
        show={open && current === 'viewDonor' && singleDonor}
        onHide={() => {
          setEdit(false);
          setOpen(false);
        }}
        title="View Donor/User"
        size="md"
        footer={
          <button
            type="button"
            className="btn btn-primary"
            disabled={singleDonor?.isLoading}
            onClick={() => {
              if (edit) {
                setEdit(false);
                updateMyData('donor');
              } else {
                setEdit(true);
              }
            }}
          >
            {updateDonorMutation.isLoading && <LoadingIndicator />}
            {edit ? 'Update' : 'Edit'}
          </button>
        }
      >
        {singleDonor?.isLoading && <LoadingIndicator />}
        <dl className="striped">
          {singleDonor?.data &&
            Object.entries(singleDonor?.data)?.map(([key, value]) =>
              excludedFields.includes(key) ? (
                []
              ) : key === 'state' || key === 'country' || key === 'city' ? (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  <dd className="col-8">{value?.name}</dd>
                </div>
              ) : key==='category'||key==='present_level_contribution'||key==='next_level_contribution'?(
                <div></div>
              )
              
              
              
              : key === 'updated_at' || key === 'created_at' ? (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  <dd className="col-8">
                    {format(new Date(value), 'yyyy/MM/dd')}
                  </dd>
                </div>
              ) : typeof value === 'boolean' ? (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  <dd className="col-8">{value ? 'Yes' : 'No'}</dd>
                </div>
              ) : (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  {(!edit || key === 'unique_id' || key === 'email') && (
                    <dd className="col-8">{value}</dd>
                  )}
                  {edit && key !== 'unique_id' && key !== 'email' && (
                    <div className="col-8">
                      {key === 'gender' ? (
                        <select
                          className="form-select"
                          defaultValue={value || ''}
                          onChange={(e) =>
                            setCurrentData({
                              ...currentData,
                              [key]: e.target.value,
                            })
                          }
                        >
                          <option value="" disabled>
                            Choose one...
                          </option>
                          <option value="female">Female</option>
                          <option value="male">Male</option>
                        </select>
                      ) : (
                        <input
                          className="form-control"
                          type={key === 'date_of_birth' ? 'date' : 'text'}
                          defaultValue={value}
                          onChange={(e) =>
                            setCurrentData({
                              ...currentData,
                              [key]: e.target.value,
                            })
                          }
                        />
                      )}
                    </div>
                  )}
                </div>
              )
            )}
        </dl>
      </Modals>
      <Modals
        show={open && current === 'viewDonation' && singleDonation}
        onHide={() => {
          setEdit(false);
          setOpen(false);
        }}
        size={'md'}
        title="View Donation"
        footer={
          <>
            <button
              type="button"
              className="btn btn-primary"
              disabled={
                singleDonation?.isLoading || updateDonationMutation.isLoading
              }
              onClick={() => {
                if (edit) {
                  setEdit(false);
                  updateMyData('donation');
                } else {
                  setEdit(true);
                }
              }}
            >
              {updateDonationMutation.isLoading && <LoadingIndicator />}
              {edit ? 'Update' : 'Edit'}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={
                singleDonation?.isLoading || updateDonationMutation.isLoading
              }
              onClick={() => {
                if (singleDonation?.data?.is_active) {
                  updateMyData('donation_deactivate');
                } else {
                  updateMyData('donation_activate');
                }
              }}
            >
              {updateDonationMutation.isLoading && <LoadingIndicator />}
              {singleDonation?.data?.is_active ? 'Deactivate' : 'Activate'}
            </button>
          </>
        }
      >
        {singleDonation?.isLoading && <LoadingIndicator />}
        <div>
          {singleDonation?.data &&
            Object.entries(singleDonation?.data)?.map(([key, value]) =>
              excludedFields.includes(key) ? (
                []
              ) : key === 'updated_at' || key === 'created_at' ? (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  <dd className="col-8">
                    {format(new Date(value), 'yyyy/MM/dd')}
                  </dd>
                </div>
              ) : key === 'start_date' || key === 'end_date' ? (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  {!edit && (
                    <>
                      <dd className="col-8">
                        {format(new Date(value), 'yyyy/MM/dd')}
                      </dd>
                    </>
                  )}

                  {edit && (
                    <>
                      <dd className="col-8">
                        <input
                          className="form-control"
                          type="date"
                          defaultValue={value}
                          onChange={(e) =>
                            setCurrentData({
                              ...currentData,
                              [key]: e.target.value,
                            })
                          }
                        />
                      </dd>
                    </>
                  )}
                </div>
              ) : (
                <div key={key} className="row">
                  <dt className="col-4">{`${startCase(key)} :`}</dt>
                  {!edit && <dd className="col-8">{value}</dd>}
                  {edit && (
                    <div className="col-8">
                      <input
                        className="form-control"
                        type="text"
                        defaultValue={value}
                        onChange={(e) =>
                          setCurrentData({
                            ...currentData,
                            [key]: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                </div>
              )
            )}
        </div>
      </Modals>
    </>
  );
};

export default DonationMgt;
