import useAdminActions from "hook/useAdminActions";
import React, { useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { notify } from "components/Alerts";
import { format } from "date-fns";
import { formatMoney, toFullName, toStartCase } from "utils/functions";
import useGenericActions from "./useGenericAction";
import ButtonWithTips from "components/ButtonWithTips";
import useUserReport from "./reports/useUserReport";

const validationSchema = Yup.object({
  first_name: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s .!?,"-]+$/,
      'field accepts only string, numbers and hyphens'
    )
    .required('Field cannot be empty'),
  last_name: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s .!?,"-]+$/,
      'field accepts only string, numbers and hyphens'
    )
    .required('Field cannot be empty'),
  email: Yup.string()
    .email('must be valid email')
    .required('Must not be empty'),
  phone_home: Yup.string()
    .length(11, 'Phone number must be 11 digits only')
    .matches(/^\d+$/, 'Phone number must be digit 0-9 only'),
  phone_mobile: Yup.string()
    .required('Field cannot be empty')
    .length(11, 'Phone number must be 11 digits only')
    .matches(/^\d+$/, 'Phone number must be digit 0-9 only'),
  date_of_birth: Yup.string()
    .required('Must not be empty'),
  address: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s .!?,"-]+$/,
      'field accepts only string, commas, numbers and hyphens'
    )
    .required('Field cannot be empty'),
  country_id: Yup.number()
    .required('Must not be empty'),
  state_id: Yup.number()
    .required('Must not be empty'),
  city_id: Yup.number()
    .required('Must not be empty'),
  status_id: Yup.number()
    .required('Must not be empty'),

})

const validationSchemaDonations = Yup.object({
  tagline: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s .!?,"-]+$/,
      'field accepts only string, numbers and hyphens'
    )
    .required('Field cannot be empty'),
  description: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s .!?,"-]+$/,
      'field accepts only string, numbers and hyphens'
    )
    .required('Field cannot be empty'),
  amount: Yup.string()
    .matches(
      /^[0-9\s,]+$/,
      'field accepts only string, commas, numbers and hyphens'
    )
    .required('Must not be empty'),
  start_date: Yup.string()
    .required('Must not be empty'),
  end_date: Yup.string()
    .required('Must not be empty'),
  category_id: Yup.number()
    .required('Must not be empty'),
})

const validationSchemaMakeDonations = Yup.object({
  donor_name: Yup.string()
    .matches(
      /^[a-zA-Z0-9\s .!?,"-]+$/,
      'field accepts only string, numbers and hyphens'
    )
    .required('Field cannot be empty'),
  donor_email: Yup.string()
    .email('must be valid email')
    .required('Must not be empty'),
  donor_status_id: Yup.number()
    .required('Must not be empty'),
  donation_type: Yup.string()
    .required('Must not be empty'),
  donation_id: Yup.object()
    .required('Must not be empty'),
})

const useDonationManagement = () => {
  const queryClient = useQueryClient()
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCycle, setSelectedCycle] = useState('');
  const [donors, setDonors] = React.useState([])
  const [donations, setDonations] = React.useState([])
  const [search, setSearch] = React.useState('');
  const [level, setSearchLevel] = React.useState('');
  const [status, setSearchStatus] = React.useState('');

  const [selectedCity2, setSelectedCity2] = React.useState('');
  const [selectedState2, setSelectedState2] = React.useState('');
  const [sFrom, setFrom] = React.useState('');
  const [sTo, setTo] = React.useState('');

  const [pagesize, setPageSize] = React.useState(100)
  const [page, setPage] = React.useState(1)
  const [page2, setPage2] = React.useState(1)
  const [skipPageReset] = React.useState(false)
  const [currentData, setCurrentData] = React.useState(null)
  const [current, setCurrent] = React.useState('')
  const [edit, setEdit] = React.useState(false)
  const [categories, setCategories] = React.useState(null)
  const [donationId, setDonationId] = useState('')
  const [open, setOpen] = React.useState(false)
  const [donorOpts, setDonorOpts] = React.useState(null)
  const [selectedStatus, setSelectedStatus] = React.useState(null)
  const [donationType, setDonationType] = React.useState(null)

  const [selectedDonor, setSelectedDonor] = React.useState(null)
  const [selectedReferral, setSelectedReferral] = React.useState(null)
  const {
    getAllDonorStatus, 
    getAllDonationCategories,
    getAllDonors,
    createADonor,
    updateADonor,
    messageADonor,
    deleteADonor, 
    getADonor,
    getAllDonations,
    createADonation,
    updateADonation,
    deleteADonation,
    getADonation } = useAdminActions()
  const { makeDonation } = useGenericActions()

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: (row) => toStartCase(`${row.first_name} ${row.last_name}`),
      },
      {
        Header: 'Contact no',
        // accessor: 'phone_mobile',
        accessor: (row) =>row.phone_home=!null?row.phone_home:''+' '+row.phone_mobile,
      },
      {
        Header: 'Others',
        // accessor: 'phone_mobile',
        accessor: (row) =>row.others, 
      }, 
      {
        Header: 'Level',
        id: 'level',
        accessor: (row) => row?.has_paid_single_sign_on_fee ? toStartCase(row?.category?.name) : "Prospect",
      },
      {
        Header: 'City',
        id: 'city',
        accessor: (row) => toStartCase(`${row.city?.name}`),
      },
      {
        Header: 'Total Donation',
        accessor: (row) => formatMoney(`${row.total_contribution}`),
      },
      {
        Header: 'Last Donation Date',
        accessor: (row) => `${row.transactions.length==0 ?0: format(new Date(row.transactions[0]?.created_at), 'dd-MM-yyyy hh:mm') }`,
        // accessor: (row) => formatMoney(`${row.transactions.length==0 ?0: row.transactions[0]?.amount}`),
      },
      {
        Header: 'Donor Status',
        id: 'status',
        accessor: (row) => toStartCase(row?.donor_status?.name),
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex flex-wrap gap-2 w-100">
            <ButtonWithTips id='viewDonor' tips="View donor's details" btnProps={{
              className: "btn btn-secondary", onClick: () => {
                setCurrent('viewDonor')
                handleclick(row.row.original)
              }
            }} ><i className="fas fa-eye" /></ButtonWithTips>
            <ButtonWithTips id='sendMessage' tips="Message donor" btnProps={{
              className: "btn btn-primary", onClick: () => {
                setCurrent('messageDonation')
                handleclick(row.row.original)
              }
            }}  ><i className="fas fa-envelope" /></ButtonWithTips>
          </div>
        ),
      }
    ],
    []
  )

  const MembershipColumns = useMemo(
    () => [
      {
        Header: 'Name',
        id: 'name',
        accessor: (row) => toStartCase(`${row.first_name} ${row.last_name}`),
      },
      {
        Header: 'Contact no',
        // accessor: 'phone_mobile',
        accessor: (row) =>row.phone_home=!null?row.phone_home:''+' '+row.phone_mobile,
      },
      {
        Header: 'Others',
        // accessor: 'phone_mobile',
        accessor: (row) =>row.others, 
      },
      {
        Header: 'Level',
        id: (row) =>row.present_level_contribution.id,
        accessor: (row) =>row.present_level_contribution?.level,
      },
      {
        Header: 'Total Donation',
        accessor: 'total_contribution',
      },
      {
        Header: 'State',
        id: 'state',
        accessor: (row) => toStartCase(`${row.state?.name}`),
      },
      {
        Header: 'City',
        id: 'city',
        accessor: (row) => toStartCase(`${row.city?.name}`),
      },
      {
        Header: 'Date joined',
        id: 'date',
        accessor: row => format(new Date(row?.created_at), 'MMM dd yyyy'),
      },
      
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex flex-wrap gap-2 w-100">
            <ButtonWithTips id='viewDonor' tips="View donor's details" btnProps={{
              className: "btn btn-secondary", onClick: () => {
                setCurrent('viewDonor')
                handleclick(row.row.original)
              }
            }} ><i className="fas fa-eye" /></ButtonWithTips>
            <ButtonWithTips id='sendMessage' tips="Message donor" btnProps={{
              className: "btn btn-primary", onClick: () => {
                setCurrent('messageDonation')
                handleclick(row.row.original)
              }
            }}  ><i className="fas fa-envelope" /></ButtonWithTips>
          </div>
        ),
      }
    ],
    []
  )

  const donationColumn = useMemo(
    () => [

      {
        Header: 'Creator',
        id: 'creator',
        accessor: (row) => toStartCase(`${row?.donation_creator[0]?.first_name} ${row?.donation_creator[0]?.last_name}`),
      },
      {
        Header: 'Status',
        id: 'level',
        accessor: (row) => row?.is_active ? 'Active' : 'Inactive',
      },
      {
        Header: 'Tagline',
        id: 'tagline',
        accessor: (row) => toStartCase(row?.tagline),
      },
      {
        Header: 'Duration',
        id: 'duration',
        accessor: (row) => `${format(new Date(row.start_date), 'dd/MM/yyyy')} - ${format(new Date(row.end_date), 'dd/MM/yyyy')}`,
      },
      {
        Header: 'Date',
        id: 'created_at',
        accessor: (row) => format(new Date(row.created_at), 'dd/MM/yyyy'),
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex flex-wrap gap-2 w-100">
            <ButtonWithTips id='viewDonation' tips="View donation details" btnProps={{
              className: "btn btn-secondary", onClick: () => {
                setCurrent('viewDonation')
                handleclick(row.row.original)
              }
            }} ><i className="fas fa-eye" /></ButtonWithTips>
          </div>
        ),
      }
    ],
    []
  )

  const { data: statuses, isLoading: isStatusLoading } = useQuery(['donation-status'], getAllDonorStatus, {
    onSuccess: (res) => {
      const statusesOpt = res.data.donor_statuses.data?.map(item => {
        return { id: item.id, value: item.id, label: item.name }
      })
      return statusesOpt
    },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })

  const { isLoading: isCategoriesLoading } = useQuery(['donation_categories'], getAllDonationCategories, {
    onSuccess: (res) => {
      const _categoriesOpt = res.data.donation_categories.data?.map(item => {
        return { id: item.id, value: item.id, label: item.name }
      })
      setCategories(_categoriesOpt)
    },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })

  const allDonations = useQuery(['donations', page2], () => getAllDonations(page2), {

    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })


  const { data: donationTypes } = useQuery(['donations'], getAllDonations, {
    select: (res) => {
      const permOpt = res.data.donations.data.map(item => {
        return { id: item.id, value: item.id, label: item.tagline }
      })

      return permOpt
    },
    onError: err => {
      console.log(err)
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })

  const singleDonation = useQuery(['donations', 'id', currentData?.id], () => getADonation(currentData?.id), {
    select: data => data.data.donation,
    onError: err => {
      notify(err.message, { type: 'error' })
    },
    staleTime: Infinity,
    enabled: current === 'viewDonation'
  })

  const createDonationMutation = useMutation(createADonation, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('donations')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      console.log(err)
      notify(err.message, { type: 'error' })
    }
  })

  const updateDonationMutation = useMutation(updateADonation, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('donations')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify(err, { type: 'error' })
    }
  })

  const deleteDonationMutation = useMutation(deleteADonation, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('donations')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      console.log(err)
      notify(err.message, { type: 'error' })
    }
  })


  const makeDonationMutation = useMutation(makeDonation, {
    onSuccess: (res) => {
      formik3.resetForm() 
      setSelectedStatus(null)
      setSelectedCategory(null)
      setSelectedDonor(null)
      setSelectedReferral(null)
      setSelectedCycle(null)
      window.open(res.data.authorization_url, '_blank', 'noopener,noreferrer');
      // queryClient.invalidateQueries('donations')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify(err.message, { type: 'error' })
    }
  })

  const allDonors = useQuery(['donors', page, search, level,selectedState2,selectedCity2,sFrom,sTo,pagesize,status], () => getAllDonors(page,search,level,selectedState2,selectedCity2,sFrom,sTo,pagesize,status), {
    onSuccess: (res) => {
      // setDonors(res.data.users.data)
      const permOpt = res.data.users.data.map(item => {
        return { id: item.id, value: item.id, email: item.email, phone: item.phone_mobile, label: toFullName({ firstName: item.first_name, lastName: item.last_name }) }
      })
      setDonorOpts(permOpt)
    },
    onError: err => {
      console.log(err)
      // notify('post creation failed!', { type: 'error' })
    },
    staleTime: Infinity,
    cacheTime: Infinity
  })

  const singleDonor = useQuery(['donors', 'id', currentData?.id], () => getADonor(currentData?.id), {
    select: data => data.data.user,
    onError: err => {
      notify(err.message, { type: 'error' })
    },
    staleTime: Infinity,
    enabled: current === 'viewDonor'
  })

  const messageDonor = useQuery(['donors', 'id', currentData?.id], () => getADonor(currentData?.id), {
    select: data => data.data.user,
    onError: err => {
      notify(err.message, { type: 'error' })
    },
    staleTime: Infinity,
    enabled: current === 'messageDonation'
  })

  const handleDonationTypeChange = (e, name) => {
    debugger
    formik3.setFieldValue(name, e);
    setDonationId(e.id);
  }
  const createDonorMutation = useMutation(createADonor, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('donors')
      setOpen(false)
      formik.resetForm()
      setSelectedStatus(null)
      setDonationType(null)
      setSelectedCategory(null)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify(err.message, { type: 'error' })
    }
  })

  

  const messageDonorMutation = useMutation(messageADonor, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('donors')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify(err, { type: 'error' })
    }
  })

  const updateDonorMutation = useMutation(updateADonor, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('donors')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify(err, { type: 'error' })
    }
  })

  const deleteDonorMutation = useMutation(deleteADonor, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('donors')
      setOpen(false)
      notify(res.message, { type: 'success' })
    },
    onError: err => {
      notify(err.message, { type: 'error' })
    }
  })

  const updateMyData = (_type) => {
    if (_type === 'donor') {
      const details = {
        id: currentData.id,
        first_name: currentData.first_name,
        last_name: currentData.last_name,
        email: currentData.email,
        others: currentData.others,
        phone_home: currentData.phone_home,
        phone_mobile: currentData.phone_mobile,
        date_of_birth: currentData.date_of_birth,
        address: currentData.address,
        status_id: currentData.status_id,
        country_id: currentData.country_id,
        state_id: currentData.state_id,
        city_id: currentData.city_id,
        _method: 'PUT',
      }

      updateDonorMutation.mutate(details)
    } 
    else if( _type==='message_donor'){
      const details = {
        id: currentData.id,
        email: currentData.email,
        phone_mobile: currentData.phone_mobile,
        message: currentData.message,
        _method: 'POST',
      }
      messageDonorMutation.mutate(details);
    }
    else if (_type === 'donation' || _type === 'donation_activate' || _type === 'donation_deactivate') {
      const details = {
        id: currentData.id,
        description: currentData.description,
        tagline: currentData.tagline,
        amount: currentData.amount.split('.')[0],
        category_id: currentData.category_id,
        start_date: currentData.start_date,
        end_date: currentData.end_date,
        is_active: _type === 'donation_activate' ? 1 : 0,
        _method: 'PUT',
      }
      updateDonationMutation.mutate(details)

    }

  }

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }

  const getDefaultValues = (arr) => {
    const permOpt = arr.map(item => {
      return { id: item.id, value: item.id, label: item.name }
    })
    return permOpt
  }

  const getDefaultDonations = (arr) => {
    const permOpt = arr.map(item => {
      return { id: item.id, value: item.id, label: item.tagline }
    })
    return permOpt
  }

  const formik = useFormik({
    validationSchema,
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_home: '',
      phone_mobile: '',
      date_of_birth: '',
      address: '',
      country_id: '',
      state_id: '',
      city_id: '',
      status_id: '',
      donation_type: '',
    },
    onSubmit: (values) => {
      const credentials = {
        ...values,
        callbackUrl: `${window.location.origin}/users/resetPassword`
      }
      createDonorMutation.mutate(credentials)
    },
  })


  const formik2 = useFormik({
    validationSchema: validationSchemaDonations,
    initialValues: {
      tagline: '',
      amount: '',
      category_id: '',
      description:'',
      start_date: '',
      end_date: '',
    },
    onSubmit: (values) => {
      
      createDonationMutation.mutate(values)
    },
  })


  const formik3 = useFormik({
    validationSchema: validationSchemaMakeDonations,
    initialValues: {
      donor_name: '',
      donor_email: '',
      donation_id: '',
      donor_user: '',
      donor_status_id: '',
      donor_phone_number: '',
      donation_type: '',
      donation_cycle: '',
      donation_group: ''
    },
    onSubmit: (values) => {
      
      values.donation_id=values.donation_id.id;
      values.callbackUrl= `${window.location.origin}/admin/donation-mgt`;
      console.log('values', values)
      makeDonationMutation.mutate(values)
    },
  })

  const default_returns = {
    formik, getDefaultValues, updateMyData, columns, donationColumn, MembershipColumns, open, setOpen, edit, setEdit, current, setCurrent, currentData, setCurrentData, skipPageReset, page,pagesize , setPage, setSearch, setSearchLevel,setSearchStatus, selectedCity, setSelectedCity, selectedState, setSelectedState, selectedCountry, setSelectedCountry
  }
  return {
    selectedDonor, setSelectedDonor,selectedReferral,setSelectedReferral, formik2, selectedCategory, setSelectedCategory,setSelectedCycle, selectedCycle, page2, setPage2, donors, setDonors, donations, setDonations, isStatusLoading, isCategoriesLoading, allDonations, singleDonation, createDonationMutation, updateDonationMutation, deleteDonationMutation, selectedStatus, setSelectedStatus, donationType,
    setDonationType, allDonors, getAllDonors,messageDonor
     ,setSelectedCity2,setSelectedState2,setFrom,setTo,search, selectedCity2,selectedState2,sFrom,sTo,level,
     singleDonor, getDefaultDonations, createDonorMutation,messageDonorMutation, updateDonorMutation, deleteDonorMutation, categories, statuses, donationTypes, donorOpts, formik3, handleDonationTypeChange, makeDonationMutation, ...default_returns
  }
}

export default useDonationManagement
