import ButtonWithTips from "components/ButtonWithTips"
import React from "react"
import { formatMoney } from "utils/functions"
import makeData from "utils/makeData"
import useReports from "./useReports"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';

const useFundraiserReport = () => {
  //  const [data, setData] = React.useState(React.useMemo(() => makeData('aausers', 100), []))
  const navigate = useNavigate()
  const { getfundRaiserReport } = useReports(); 
  const [page, setPage] = React.useState(1)
  const { data, isLoading } = useQuery(['users', page], ()=> getfundRaiserReport(page), {
    select: data => data.data.fund_raising.data
  });
  const [, setData] = React.useState(React.useMemo(() => makeData('aausers', 100), []));
  const [currentData, setCurrentData] = React.useState(null)
  const [current, setCurrent] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [edit, setEdit] = React.useState(false)
  const [skipPageReset, setSkipPageReset] = React.useState(false)
  const updateMyData = () => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true)
    setData(old =>
      old.map((row) => {
        if (row.id === currentData.id) {
          return {
            ...currentData
          }
        }
        return row
      })
    )

    setOpen(false)
  }

  const handleclick = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }
  const handleBreakdown = (obj) => {
    setOpen(true)
    setCurrentData(obj)
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Fundraise Type',
        accessor: row=> row.fund_type.name,
      },
      // {
      //   Header: 'City',
      //   accessor: 'city',
      // },
      // {
      //   Header: 'Amount',
      //   id: 'donation',       
      // },
      {
        Header: 'Target',
        accessor: row => formatMoney(row.amount)
      },
      {
        Header: 'Total donation',
        id: 'total_donation',
        accessor: row => formatMoney(row.donations[0]==null?0:row.donations[0].total)
      },
      {
        Header: 'Date',
        accessor:row => format(new Date(row.created_at),"yyyy-MM-dd"),
        filter: 'dateFilter'
      },
      {
        Header: 'Due Date',
        accessor: 'due_date',
        filter: 'dateFilter'
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: row => (
          <div className="flex flex-wrap gap-2 w-100">
            <ButtonWithTips id={`view`} tips="View Report Details" btnProps={{
              className: "btn btn-secondary", onClick: () => {
                setCurrent('view')
                handleclick(row.row.original)
              }
            }}><i className="fas fa-eye" /></ButtonWithTips>
            <ButtonWithTips id={`view`} tips="View Breakdown" btnProps={{
              className: "btn btn-secondary", onClick: () => navigate(`${row.row.original?.id}`)
            }}><i className="fas fa-eye" /></ButtonWithTips>
          </div>
          
        ),
      }
    ],
    []
  )
  return { currentData, setCurrentData, open, setOpen, updateMyData, skipPageReset, columns, data, setData,isLoading, current, edit, setEdit }
}

export default useFundraiserReport;
